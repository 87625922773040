import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// __START_DEBUG_CODE_CLEANUP__
// NOTE: Uncomment the below import statement in case you need to debug the redux store.
import { composeWithDevTools } from 'redux-devtools-extension';
// __END_DEBUG_CODE_CLEANUP__
import RootReducer from './root.reducers';
// __START_DEBUG_CODE_CLEANUP__
// NOTE: Uncomment the below line in case you need to debug the redux store.
const KaveriAppStore = createStore(RootReducer, composeWithDevTools(applyMiddleware(thunk)));
// __END_DEBUG_CODE_CLEANUP__

// NOTE: Comment the below line in case you need to debug the redux store.
// __UNCOMMENT_DEBUG_CODE__
// const KaveriAppStore = createStore(RootReducer, applyMiddleware(thunk));
export default KaveriAppStore;
