export const Events = {
    ACCESS_TOKEN_FAILED: 'accessTokenFailed',
    ACCESS_TOKEN_GET: 'accessTokenGet',
    ACCESS_TOKEN_RESTORATION: 'accessTokenRestoration',
    ACCESS_TOKEN_SUCCESSFUL: 'accessTokenSuccessful',
    AUTHENTICATION_CONFIGURATION_FAILED: 'authenticationConfigurationFailed',
    AUTHENTICATION_CONFIGURATION_GET: 'authenticationConfigurationGet',
    AUTHENTICATION_CONFIGURATION_RESTORATION: 'authenticationConfigurationRestoration',
    AUTHENTICATION_CONFIGURATION_SUCCESSFUL: 'authenticationConfigurationSuccessful',
    BODY_INFO_FAILED: 'bodyInfoFailed',
    BODY_INFO_GET: 'bodyInfoGet',
    BODY_INFO_RESTORATION: 'bodyInfoRestoration',
    BODY_INFO_SUCCESSFUL: 'bodyInfoSuccessful',
    CANCEL_SIGN_IN: 'cancelSignIn',
    DEVICE_FEATURE_FAILED: 'deviceFeatureFailed',
    DEVICE_FEATURE_GET: 'deviceFeatureGet',
    DEVICE_FEATURE_RESTORATION: 'deviceFeatureRestoration',
    DEVICE_FEATURE_SUCCESSFUL: 'deviceFeatureSuccessful',
    LICENSE_PLATE_FAILED: 'licensePlateFailed',
    LICENSE_PLATE_GET: 'licensePlateGet',
    LICENSE_PLATE_RESTORATION: 'licensePlateRestoration',
    LICENSE_PLATE_SUCCESSFUL: 'licensePlateSuccessful',
    MSO_DEFAULT_BRANDING_UI_BUNDLE_FAILED: 'msoDefaultBrandingUiBundleFailed',
    MSO_DEFAULT_BRANDING_UI_BUNDLE_GET: 'msoDefaultBrandingUiBundleGet',
    MSO_DEFAULT_BRANDING_UI_BUNDLE_SUCCESSFUL: 'msoDefaultBrandingUiBundleSuccessful',
    PROVISIONING_DATA_FAILED: 'provisioningDataFailed',
    PROVISIONING_DATA_GET: 'provisioningDataGet',
    PROVISIONING_DATA_RESTORATION: 'provisioningDataRestoration',
    PROVISIONING_DATA_SUCCESSFUL: 'provisioningDataSuccessful',
    SAML_TOKEN_FAILED: 'samlTokenFailed',
    SAML_TOKEN_GET: 'samlTokenGet',
    SAML_TOKEN_RESTORATION: 'samlTokenRestoration',
    SAML_TOKEN_SUCCESSFUL: 'samlTokenSuccessful',
    SLS_GLOBAL_ENDPOINTS_FAILED: 'slsGlobalEndpointsFailed',
    SLS_GLOBAL_ENDPOINTS_GET: 'slsGlobalEndpointsGet',
    SLS_GLOBAL_ENDPOINTS_RESTORATION: 'slsGlobalEndpointsRestoration',
    SLS_GLOBAL_ENDPOINTS_SUCCESSFUL: 'slsGlobalEndpointsSuccessful',
    SLS_SERVICE_ENDPOINTS_FAILED: 'slsServiceEndpointsFailed',
    SLS_SERVICE_ENDPOINTS_GET: 'slsServiceEndpointsGet',
    SLS_SERVICE_ENDPOINTS_RESTORATION: 'slsServiceEndpointsRestoration',
    SLS_SERVICE_ENDPOINTS_SUCCESSFUL: 'slsServiceEndpointsSuccessful',
};

export const SignInStates = {
    ACCESS_TOKEN_GET: 'ACCESS_TOKEN_GET',
    AUTH_CONFIGURATIONS_GET: 'AUTH_CONFIGURATIONS_GET',
    BODY_INFO_GET: 'BODY_INFO_GET',
    DEVICE_FEATURES_GET: 'DEVICE_FEATURES_GET',
    LICENSE_PLATE_GET: 'LICENSE_PLATE_GET',
    MSO_DEFAULT_BRANDING_UI_BUNDLE_GET: 'MSO_DEFAULT_BRANDING_UI_BUNDLE_GET',
    PROVISIONING_DATA_GET: 'PROVISIONING_DATA_GET',
    SAML_TOKEN_GET: 'SAML_TOKEN_GET',
    SLS_GLOBAL_ENDPOINT_GET: 'SLS_GLOBAL_ENDPOINT_GET',
    SLS_SERVICE_ENDPOINTS_GET: 'SLS_SERVICE_ENDPOINTS_GET',
};

export const EventProperties = {
    AUTHENTICATION_TYPE: 'authenticationType',
    DOMAIN: 'domain',
    ERROR_CODE: 'errorCode',
    ERROR_MESSAGE: 'message',
    ERROR_TYPE: 'errorType',
    EVENT_NAME: 'event_name',
    LICENSE_PLATE_URL: 'licensePlateUrl',
    REASON: 'reason',
    REFERENCE_TIME: 'referenceTime',
    SIGN_IN_STATE: 'signInState',
};

export const CancelSignInReasons = {
    APP_CLOSED: 'APP_CLOSED',
    APP_SUSPENDED: 'APP_SUSPENDED',
    APP_TERMINATED: 'APP_TERMINATED',
    LICENSE_PLATE_CODE_EXPIRED: 'LICENSE_PLATE_CODE_EXPIRED',
};
